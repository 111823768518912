import React from 'react'

import { Context } from './Context'
import * as HistoryOrder from '@arch-log/webapp.modules/project.sample_request.history/order'

export const Initializer = ({ children }) => {
  const [orderCondition, setOrderCondition] = React.useState({
    sortKey: HistoryOrder.OrderBy.ProductId,
    direction: HistoryOrder.Direction.Desc,
  })

  return (
    <Context.Provider value={{ orderCondition, setOrderCondition }}>
      {children}
    </Context.Provider>
  )
}
