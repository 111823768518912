// extracted by mini-css-extract-plugin
export var AddressContainer = "styles-module--AddressContainer--iZ4HU";
export var AddressSection = "styles-module--AddressSection--76T5G";
export var BackButton = "styles-module--BackButton--hk4pU";
export var DeliveryAddressTextContainer = "styles-module--DeliveryAddressTextContainer--H3oOn";
export var Description = "styles-module--Description--1iheG";
export var EntryContainer = "styles-module--EntryContainer--tU-ZF";
export var Icon = "styles-module--Icon--0HXgx";
export var Image = "styles-module--Image--bXjyR";
export var ManufacturerContainer = "styles-module--ManufacturerContainer--lv45g";
export var ProductNameContainer = "styles-module--ProductNameContainer--auig7";
export var RemarksContainer = "styles-module--RemarksContainer--e4Pdv";
export var TextContainer = "styles-module--TextContainer--7uYxO";
export var Title = "styles-module--Title--2Vhyn";