import React from 'react'

import * as Sort from './Sort'
import * as Containers from './Containers'
import * as Layout from './Layout'
import * as History from '@arch-log/webapp.modules/project.sample_request.history/components'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
      BodyHeader = Layout.BodyHeader,
      BodyHeaderLeft = Layout.BodyHeaderLeft,
      BodyHeaderRight = Layout.BodyHeaderRight,
    } = {},
  } = {},
}) => (
  <History.Entries.Loaders.AtOnce>
    <Sort.Initializer>
      <Wrapper>
        <Header>
          <Containers.Title />
          <Containers.Description />
        </Header>
        <Body>
          <BodyHeader>
            <BodyHeaderLeft>
              <Containers.Sorter />
            </BodyHeaderLeft>
          </BodyHeader>
          <Containers.Entries>
            <Containers.Entry />
          </Containers.Entries>
        </Body>
        <Footer>
          <Containers.Back />
        </Footer>
      </Wrapper>
    </Sort.Initializer>
  </History.Entries.Loaders.AtOnce>
)
