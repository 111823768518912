import React from 'react'

import * as History from '@arch-log/webapp.modules/project.sample_request.history/components'
import * as HistoryOrder from '@arch-log/webapp.modules/project.sample_request.history/order'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as BaseSorter from '@arch-log/webapp.shared/Sorter'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as Sort from './Sort'
import * as styles from './styles.module.scss'

export const Sorter = () => {
  const { setOrderCondition } = React.useContext(Sort.Context)
  const { formatSortKey } = Literals.useLiterals()

  return (
    <BaseSorter.Default
      defaultValue={HistoryOrder.OrderBy.ProductId}
      sortKeys={HistoryOrder.SortKeys}
      formatSortKey={(v) => formatSortKey(v)}
      onChangeValue={(key, direction) => {
        setOrderCondition({ sortKey: key, direction })
      }}
    />
  )
}

/**
 */
export const Title = ({
  components: { Container = Styled.TitleContainer, Title = Styled.Title } = {},
}) => (
  <Container>
    <Title>
      <Literals.Title />
    </Title>
  </Container>
)

/**
 */
export const Description = ({
  components: { Decorator = Styled.Description } = {},
}) => (
  <Decorator>
    <Literals.Description />
  </Decorator>
)

/**
 */
export const Entries = ({ children }) => {
  const {
    orderCondition: { sortKey, direction },
  } = React.useContext(Sort.Context)

  return (
    <History.Entries.Sort orderBy={sortKey} direction={direction}>
      <History.Entries.Each>{children}</History.Entries.Each>
    </History.Entries.Sort>
  )
}

/**
 */
export const Entry = ({
  components: {
    Container = Styled.EntryContainer,
    TextContainer = Styled.TextContainer,
  } = {},
}) => (
  <Container>
    <ProductImage />
    <TextContainer>
      <ProductName />
      <ManufacturerDisplayName />
      <DeliveryAddressText />
      <DeliveryAddress />
      <Remarks />
    </TextContainer>
  </Container>
)

/**
 */
export const ProductImage = ({
  components: { Container = Styled.ProductImageContainer } = {},
}) => (
  <Container>
    <History.Entry.Product.Properties.Images.First>
      <History.Entry.Product.Properties.Image.Thumbnail
        className={styles.Image}
      />
    </History.Entry.Product.Properties.Images.First>
  </Container>
)

/**
 */
export const ManufacturerDisplayName = ({
  components: { Container = Styled.ManufacturerDisplayNameContainer } = {},
}) => (
  <Container>
    <History.Entry.Product.Properties.Identifier.Manufacturer.DisplayName />
  </Container>
)

/**
 */
export const ProductName = ({
  components: { Container = Styled.ProductNameContainer } = {},
}) => (
  <Container>
    <History.Entry.Product.Properties.ProductName />
  </Container>
)

/**
 */
export const DeliveryAddressText = ({
  components: { Container = Styled.DeliveryAddressTextContainer } = {},
}) => (
  <Container>
    <Styled.AddressesIcon />
    <Literals.DeliveryAddressText />
  </Container>
)

/**
 */
export const DeliveryAddress = ({
  components: {
    Container = Styled.AddressContainer,
    Section = Styled.AddressSection,
  } = {},
}) => (
  <Container>
    <Section>
      <History.Entry.Address.Properties.Company />
      <History.Entry.Address.Properties.Division />
      <History.Entry.Address.Properties.LastName />
      <History.Entry.Address.Properties.FirstName />
    </Section>
    <Section>
      <History.Entry.Address.Properties.PostalCode />
      <History.Entry.Address.Properties.StateOrPrefecture />
      <History.Entry.Address.Properties.City />
      <History.Entry.Address.Properties.Street />
      <History.Entry.Address.Properties.Building />
    </Section>
    <Section>
      <History.Entry.Properties.Orderdate />
    </Section>
    <Section>
      <History.Entry.Properties.Quantity prefix={<Literals.Quantity />} />
    </Section>
  </Container>
)

/**
 */
export const Remarks = ({
  components: { Container = Styled.RemarksContainer } = {},
}) => (
  <Container>
    <History.Entry.Properties.Remarks />
  </Container>
)

/**
 */
export const Back = () => {
  return (
    <ProjectLinks.ToSampleRequests className={styles.BackButton}>
      <Literals.Back />
    </ProjectLinks.ToSampleRequests>
  )
}
