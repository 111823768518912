import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('RequestHistory.Title')

/**
 */
export const Description = translated('app.project')(
  'RequestHistory.Description',
)

/**
 */
export const Quantity = translated('app.project')('RequestHistory.Quantity')

/**
 */
export const DeliveryAddressText = translated('app.project')(
  'RequestHistory.DeliveryAddressText',
)

/**
 */
export const Back = translated('app.project')('RequestHistory.Back')

export const useLiterals = () => {
  const { t } = useTranslation('app.project')

  const formatSortKey = (key, ...options) =>
    t(`RequestHistory.SortKeys.${key}`, ...options)

  return {
    formatSortKey,
  }
}
