import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const TitleContainer = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)

/**
 */
export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

/**
 */
export const EntryContainer = ({ children }) => {
  return <div className={styles.EntryContainer}>{children}</div>
}
/**
 */
export const TextContainer = ({ children }) => {
  return <div className={styles.TextContainer}>{children}</div>
}

/**
 */
export const DeliveryAddressTextContainer = ({ children }) => {
  return <div className={styles.DeliveryAddressTextContainer}>{children}</div>
}

/**
 */
export const ProductNameContainer = ({ children }) => {
  return <div className={styles.ProductNameContainer}>{children}</div>
}

/**
 */
export const ManufacturerDisplayNameContainer = ({ children }) => {
  return <div className={styles.ManufacturerContainer}>{children}</div>
}

/**
 */
export const ProductImageContainer = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const RemarksContainer = ({ children }) => {
  return <div className={styles.RemarksContainer}>{children}</div>
}

/**
 */
export const Remarks = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const AddressContainer = ({ children }) => {
  return <div className={styles.AddressContainer}>{children}</div>
}

/**
 */
export const AddressSection = ({ children }) => {
  return <div className={styles.AddressSection}>{children}</div>
}

/**
 */
export const AddressesIcon = () => <Icons.Addresses className={styles.Icon} />
